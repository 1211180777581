@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .title {
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
      0px 18px 23px rgba(0, 0, 0, 0.1);
  }

  button {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .email,
  .name,
  .password {
    margin-bottom: 8px;
  }

  .hero-image {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .search-input {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .card-container {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  }

  .home-card-container {
    width: 400px;
    margin: 80px auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  @media screen and (max-width: 640px) {
    .home-card-container {
      width: 300px;
    }
  }
}
